* {
  margin: 0;
  padding: 0;
}


  :root {
    --icon-color: rgba(255, 255, 255, .55);
    --theme-color: #204570;

    --decorate-color: #ff99cc;
}

body {
  background-color: #F4F6FF;
  overflow-x: hidden;
}
a{
  text-decoration: none!important;
}
p{
  margin: 0 !important;
}

.bg-color {
  background: linear-gradient(90deg, rgba(0, 4, 36, 1) 0%, rgba(8, 134, 161, 1) 0%,
  rgba(9, 9, 121, 1) 100%);
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

img {
  width: 100%;
}
.banner-img {
  height: 640px;
  object-fit: cover;
}

.section-gap {
  margin-top: 100px;
  margin-bottom: 100px;
}

.gap-1 {
  margin-top: 80px;
  margin-bottom: 80px;
}

/* top-bar start */
.top-bar {
  background-color: var(--icon-color);
  padding: 5px 0;

}

.top-bar-link a {
  color: gray;
  padding: 0 15px;

}
.registration , .service-book{
  label{
    width: 100%;
    font-size: 14px ;
    margin-top: 10px;
  }
}
.top-bar-link a:hover {
  color: var(--theme-color);
  transition: .3s all ease-in-out;
}



.top-bar-link a:last-of-type:after {
  content: '';
}

.top-bar-social a {
  color: gray;
  padding-right: 10px;
}

.top-bar-social a:hover {
  color: var(--theme-color);
  transition: .3s all ease-in-out;
}

/* top-bar end */
/* navbar start */
.navbar {
  background-color: var(--theme-color);
  padding: 5px 0;
}

.logo-image {
  height: 90px;
  padding: 15px 0;
  width: auto;
}

.nav-item i {
  margin-right: 5px;
}

.nav-item li a:hover {
  color: #fff;
  transition: .3s all ease-in-out;
}

#nav-menu li:hover .sub-nav-parent {
  display: block;
  transition: .3s all ease-in-out;
}

.nav-item li {
  padding: 15px;
}

.nav-item li a {
  color: var(--icon-color);
  font-weight: 800;
  font-size: 17px;
}

.sub-nav-parent {
  background: var(--theme-color);
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: 60px;
  transform: translateX(-25%);
  width: 250px;
  z-index: 9999;
  text-align: center;
  padding: 25px 0;
  display: none;
}

.sub-nav-parent li {
  padding: 5px !important;
}

.sub-nav-parent li a {
  padding: 05px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}


/* navbar end */
/* slider start */

.slider-item {
  position: relative;
}

#slider img {
  object-fit: cover;
  width: 100vw;
}

.slick-prev {
  left: 30px;
  z-index: 3;
}

.slick-next {
  right: 30px;
}

.slick-dots {
  bottom: 25px;
  color: rgb(211, 205, 205);
  z-index: 4;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #fff;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: 1;
}

/* slider end */
/* banner start */
.banner-schedule {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #6d6d6d;
  padding: 10px;
  position: relative;
  text-align: center;
  margin: 0 auto;
}

.banner-schedule h4 {
  color: var(--theme-color);
  padding-bottom: 10px !important;
  text-transform: uppercase;
  margin-top: 10px;
}

.banner-schedule h1 a {
  color: var(--decorate-color)
}

.banner-schedule h6 {
  color: var(--decorate-color);
  padding-bottom: 10px !important;
}

.banner-schedule p span {
  color: gray;
}

/* banner end */
/* service start */
.custom-div {
  color: #fff;
  background-color: var(--bg-color);
  font-size: 30px;
  text-transform: capitalize;
  border-radius: 5px;
}

.custom-service {
  text-align: center;
  color: #fff;
  margin: 0 auto;
  border-radius: 5px;
  text-transform: capitalize;
}

.service-image img {
  width: 100%;
  height: 90px;
  vertical-align: middle;
  border-radius: 5px;
}

.custom-font-h {
  font-weight: 500;
  text-transform: capitalize;
}

.content p {
  color: #8C8C8C;
}

.custom-btn {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
}

.white-anchore {
  color: #fff;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.no-card-border {
  border: none;
}

.card-footer {
  border-top: 0;
}

/* service end */
/* expertise start */
.expertise img {
  width: 342px;
  height: 230px;
  border-radius: 5px;
}

.expertise {
  width: 362px;
}

.img-slider h4 {
  text-align: center;
  color: gray;
  text-transform: capitalize;
}

/* expertise end*/
/* trash area start */
.trash-h4 {
  font-size: 30px;
}

.trash-img img {
  width: 540px;
  height: 400px;
  border-radius: 5px;
}

.trash-heading {
  text-transform: capitalize;
}

.trash-heading p {
  color: var(--decorate-color) !important;
}

.trash-content p {
  color: gray;
  font-size: 18px;
}

.number p {
  color: var(--decorate-color);
  font-size: 40px;
}

.num-content {
  font-size: 25px !important;
}

/* trash area end */
/* clint-feadback start*/
.feadback-text {
  font-size: 20px;
  text-align: center;
  font-weight: 400;

}

.left-icon ,
.right-icon  {
  color: var(--theme-color);
  z-index: 1;
  font-size: 25px;
}



/* clint-feadback  end*/
/* blog start */
.img-slider {
  width: 360px;
}

.blog-text {
  color: var(--theme-color) !important;
}

.blog-date {
  color: gray;
  font-size: 13px;
  padding-top: 10px;
}

.blog-footer button {
  border: 1px solid var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 5px;
  padding: 1px 8px;
  font-size: 14px;
}

.blog-footer button:hover {
  background-color: var(--theme-color);
  color: #fff;
  transition: .3s all ease-in-out;
}

/* blog end */
/* map start */
.i4ewOd-pzNkMb-haAclf {
  background-color: var(--theme-color) !important;
}

/* footer start */
.bg-footer {
  background-color: #111D5C;
  width: 100%;

}

.footer img {
  width: 220px;
}

.footer p {
  color: #CBE;
}

.footer i {
  color: #CBE;
  font-size: 20px;
}

.footer-h2 {
  color: #CBE;
}

.service-footer-text a {
  display: block;
  text-align: left;
  padding-left: 20px;
  color: #CBE;
}

.contact-footer-text a {
  color: #CBE;
  display: block;
  text-align: left;
  font-size: 13px;
}
.text-justify{
  text-align: justify;
}

.text-14{
  font-size: 14px;
}

.contact-footer-text i {
  font-size: 14px;
}

.bg-theme {
  background-color: var(--theme-color);
  cursor: pointer;
}

.bg-theme:hover {
  background-color: #2d497e;
}

.privacy-link a {
  color: #CBE;
}

.footer-bar {
  background-color: #0C164C;
}

.custom-color {
  color: #CBE;
  padding: 23px 0;
  text-align: center;
  font-size: 14px;
}


.slider-form {
  z-index: 99;
  width: 354px;
  position: absolute;
  top: 180px;
  right: 130px;
}

.custom-icon {
  position: absolute;
  right: -3%;
  font-size: 30px !important;
  top: -2%;
  bottom: 10%;
  color: #0a58ca;
  cursor: pointer;
  z-index: 999;
}

.form-btn button {
  width: 160px;
  color: #fff;
  padding: 10px;
  font-weight: 500;

}

.form-btn button:hover {
  color: #ff99ccc1;
  transition: .3s all ease-in-out;
}

.form-footer-btn button:hover {
  color: #ff99ccc1 !important;
  transition: .3s all ease-in-out;
}
/* footer end */
.common-banner{
  position: relative;
  img{
    width: 100%;
    max-height: 450px;
    object-fit: cover;
  }
  .content{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    h1{
      color: white;
    }
  }
}
.image-containers{
  img{
    object-fit: cover;
    height: 300px;
    box-shadow: 0 0 25px silver;
    transition: all .3s  ease-in-out;
    cursor: pointer;
    &:hover{
      transform: scale(1.05);
    }
  }
}
.about-us-section{
  ul{
    li{
      background: rgba(32, 69, 112, 0.1);
      padding: 5px 15px;
      margin: 5px 0;
      border-radius: 5px;
      cursor: pointer;
      transition: all .3s ease-in-out;
      &:hover{
        transform: scale(1.01);
        color: white;
        background: var(--theme-color);
      }
    }
  }
}
.contact-us a, .contact-us p {
  color: var(--theme-color);
  display: block;
  padding: 5px 10px;
}
.service-page{
  p{
    text-align: justify;
    i{
      color: green;
    }
  }
  ul{
    margin-top: 20px;
    li{
      font-size: 18px;
      i{
        color: #007580;
      }
    }
  }
}
.privacy-container p{
  margin: 5px 0 !important;
}

.privacy-container h4{
  margin-top: 50px!important;
  margin-bottom: 10px !important;
}
.privacy-container ul{
  margin-left: 40px !important;
}

@media only screen and (max-width: 500px) {
  .slider-form{
    top: 310px;
    left: 50%;
    transform: translateX(-50%);
  }
}
li.list-group-item.list-group-item-action.custom-list-item {
  font-size: 1rem;
  margin-top: 5px;
  border: 1px solid #e6e5e5;
  background: rgba(0, 100, 0, .05);
  cursor: pointer;
  word-break: break-word;
}
.color {
  background-image: linear-gradient(45deg,#092942,#00638d,#092942);
}